
































import { Component } from "vue-property-decorator";
import { Routes } from "@/types/core";
import { BaseView } from "@/core/UI/Views/BaseView";
import {
  applicationStatuses,
  applicationTypes,
  blockTypes,
  eventForms,
  nmoStatuses,
  paymentTypes,
} from "@/core/static/dict";
import { DataTableHeader } from "vuetify";
import GeneralEditorOfCatalog, {
  Tab,
} from "@widgets/libraries/GeneralEditorOfCatalog.vue";
import {
  AllDoctorPositions,
  createDoctorPosition,
  deleteDoctorPosition,
  updateDoctorPosition,
} from "@/graphql/queries/DoctorsPositions.graphql";
import {
  AllEmployeesPositions,
  createEmployeePosition,
  deleteEmployeePosition,
  updateEmployeePosition,
} from "@/graphql/queries/EmployeesPositions.graphql";
import * as rules from "@/core/validation";
import {
  AllDoctorSpecialities,
  createDoctorSpecialty,
  deleteDoctorSpecialty,
  updateDoctorSpecialty,
} from "@/graphql/queries/DoctorsSpecialities.graphql";
import {
  AllEmployeesSpecialties,
  createEmployeeSpecialty,
  deleteEmployeeSpecialty,
  updateEmployeeSpecialty,
} from "@/graphql/queries/EmployeesSpecialities.graphql";
import {
  AllDoctorAssociations,
  createDoctorAssociation,
  deleteDoctorAssociation,
  updateDoctorAssociation,
} from "@/graphql/queries/DoctorsAssociations.graphql";
import {
  CreatePartnerStatus,
  DeletePartnerStatus,
  PartnerStatuses,
  UpdatePartnerStatus,
} from "@/graphql/queries/Partners.graphql";
import DoctorsWorkplaces from "@widgets/libraries/DoctorsWorkplaces.vue";
import {
  createPersonStatus,
  deletePersonStatus,
  personStatuses,
  updatePersonStatus,
} from "@/graphql/queries/Events.graphql";

@Component({
  components: {
    DoctorsWorkplaces,
    GeneralEditorOfCatalog,
  },
})
export default class Library extends BaseView {
  protected readonly Routes: typeof Routes = Routes;

  protected staticLibraryTableHeaders: DataTableHeader[] = [
    { value: "value", text: "код" },
    { value: "text", text: "Текст" },
  ];
  protected eventForms = eventForms;
  protected paymentTypes = paymentTypes;
  protected applicationStatuses = applicationStatuses;
  protected nmoStatuses = nmoStatuses;
  protected applicationTypes = applicationTypes;
  protected blockTypes = blockTypes;

  get position(): Tab[] {
    return [
      {
        title: "Должности докторов",
        headers: [
          { text: "id", value: "id" },
          { text: "Название", value: "name" },
        ],
        labelCreateField: "Название новой должности",
        rules: [rules.required, ...rules.positionSet],
        itemName: "должность доктора",
        createMutation: createDoctorPosition,
        updateMutation: updateDoctorPosition,
        deleteMutation: deleteDoctorPosition,
        query: AllDoctorPositions,
        fieldName: "allDoctorsPositions",
        // TODO: использовать из миксина userRolesMixin
        accessToCreate:
          this.$store.getters["session/isAllow"]("CREATE_POSITION"),
        accessToView: this.$store.getters["session/isAllow"]("VIEW_POSITIONS"),
      },
      {
        title: "Должности сотрудников",
        headers: [
          { text: "id", value: "id" },
          { text: "Название", value: "name" },
        ],
        labelCreateField: "Название новой должности",
        rules: [rules.required, ...rules.positionSet],
        itemName: "должность сотрудника",
        createMutation: createEmployeePosition,
        updateMutation: updateEmployeePosition,
        deleteMutation: deleteEmployeePosition,
        query: AllEmployeesPositions,
        fieldName: "allEmployeesPositions",
        // TODO: использовать из миксина userRolesMixin
        accessToCreate:
          this.$store.getters["session/isAllow"]("CREATE_POSITION"),
        accessToView: this.$store.getters["session/isAllow"]("VIEW_POSITIONS"),
      },
    ];
  }

  get persons(): Tab[] {
    return [
      {
        title: "Статус лекторов, ведущих, менеджеров и режиссеров",
        headers: [
          { text: "id", value: "id" },
          { text: "Название", value: "name" },
        ],
        labelCreateField: "Название нового статуса",
        rules: [rules.required, ...rules.specialitySet],
        itemName: "статус",
        createMutation: createPersonStatus,
        updateMutation: updatePersonStatus,
        deleteMutation: deletePersonStatus,
        query: personStatuses,
        fieldName: "personStatuses",
        accessToCreate: true, // todo: уточнить права на создание специальностей
        accessToView: this.$store.getters["session/isAllow"]("VIEW_STATUSES"),
      },
    ];
  }

  get specialties(): Tab[] {
    return [
      {
        title: "Специальности докторов",
        headers: [
          { text: "id", value: "id" },
          { text: "Название", value: "name" },
        ],
        labelCreateField: "Название новой специальности",
        rules: [rules.required, ...rules.specialitySet],
        itemName: "специальность сотрудника",
        createMutation: createDoctorSpecialty,
        updateMutation: updateDoctorSpecialty,
        deleteMutation: deleteDoctorSpecialty,
        query: AllDoctorSpecialities,
        fieldName: "allDoctorsSpecialties",
        accessToCreate: true, // todo: уточнить права на создание специальностей
        accessToView:
          this.$store.getters["session/isAllow"]("VIEW_SPECIALTIES"),
      },
      {
        title: "Специальности сотрудников",
        headers: [
          { text: "id", value: "id" },
          { text: "Название", value: "name" },
        ],
        labelCreateField: "Название новой специальности",
        rules: [rules.required, ...rules.specialitySet],
        itemName: "специальность сотрудника",
        createMutation: createEmployeeSpecialty,
        updateMutation: updateEmployeeSpecialty,
        deleteMutation: deleteEmployeeSpecialty,
        query: AllEmployeesSpecialties,
        fieldName: "allEmployeesSpecialties",
        accessToCreate: true, // todo: уточнить права на создание специальностей
        accessToView:
          this.$store.getters["session/isAllow"]("VIEW_SPECIALTIES"),
      },
    ];
  }

  get associations(): Tab[] {
    return [
      {
        title: "Ассоциации",
        headers: [
          { text: "id", value: "id" },
          { text: "Название", value: "name" },
        ],
        labelCreateField: "Добавление ассоциации",
        rules: [rules.required, ...rules.associationSet],
        itemName: "ассоциацию",
        createMutation: createDoctorAssociation,
        updateMutation: updateDoctorAssociation,
        deleteMutation: deleteDoctorAssociation,
        query: AllDoctorAssociations,
        fieldName: "allDoctorsAssociations",
        accessToCreate:
          this.$store.getters["session/isAllow"]("CREATE_ASSOCIATION"),
        accessToView:
          this.$store.getters["session/isAllow"]("VIEW_ASSOCIATIONS"),
      },
    ];
  }

  get partnersStatuses(): Tab[] {
    return [
      {
        title: "Статусы партнеров",
        headers: [
          { text: "id", value: "id" },
          { text: "Статус", value: "status" },
        ],
        labelCreateField: "Название нового статуса партнера",
        rules: [rules.required, rules.cyrillic_alpha_spaces],
        itemName: "статус партнера",
        createMutation: CreatePartnerStatus,
        updateMutation: UpdatePartnerStatus,
        deleteMutation: DeletePartnerStatus,
        query: PartnerStatuses,
        fieldName: "partnerStatuses",
        accessToCreate: true, // todo: уточнить права на доступ
        accessToView: true,
      },
    ];
  }
}
