var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{ref:"list",attrs:{"query":_vm.query},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [_c('PageCard',[_c('v-subheader',[_vm._v("Добавить место работы в справочник")]),_c('v-card-text',[_c('v-select',{attrs:{"label":"Тип учреждения","items":_vm.hospitalTypes,"dense":""},model:{value:(_vm.createWorkplaceHospitalType),callback:function ($$v) {_vm.createWorkplaceHospitalType=$$v},expression:"createWorkplaceHospitalType"}}),_c('ApolloMutation',{attrs:{"mutation":_vm.createMutation,"variables":{
          name: _vm.createWorkplaceName,
          hospital_type: _vm.createWorkplaceHospitalType,
        }},on:{"done":function($event){query.refetch();
          _vm.createWorkplaceName = '';
          _vm.$refs.createForm.reset();}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var result = ref.result;
          var mutate = ref.mutate;
          var loading = ref.loading;
return [_c('v-form',{ref:"createForm",on:{"submit":function($event){$event.preventDefault();return mutate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{ref:"createField",attrs:{"label":"Добавление места работы","name":"speciality","rules":_vm.rules,"loading":loading,"disabled":loading},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary","disabled":!_vm.valid,"loading":loading},on:{"click":mutate}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1)]},proxy:true}],null,true),model:{value:(_vm.createWorkplaceName),callback:function ($$v) {_vm.createWorkplaceName=$$v},expression:"createWorkplaceName"}})],1)]}}],null,true)})],1),_c('v-divider',{staticClass:"my-4"}),_c('v-card-text',{staticClass:"black--text"},[_vm._v(" Вы можете отредактировать любой элемент ")]),_c('v-data-table',{attrs:{"dense":"","loader-height":1,"items-per-page":5,"loading":isLoading > 0,"headers":[
        { text: 'id', value: 'id' },
        { text: 'Тип учреждения', value: 'hospital_type' },
        { text: 'Название', value: 'name' } ],"items":data ? data.allDoctorsWorkplaces : []},scopedSlots:_vm._u([{key:"item.hospital_type",fn:function(ref){
      var item = ref.item;
return [_c('ApolloMutation',{attrs:{"mutation":_vm.updatMutation},on:{"done":function($event){return query.refetch()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var mutate = ref.mutate;
      var loading = ref.loading;
return [_c('v-select',{attrs:{"items":_vm.hospitalTypes,"value":item.hospital_type,"loading":loading},on:{"change":function($event){return mutate({
                variables: {
                  id: item.id,
                  name: item.name,
                  hospital_type: $event,
                },
              })}}})]}}],null,true)})]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('ApolloMutation',{attrs:{"mutation":_vm.updatMutation},on:{"done":function($event){return query.refetch()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var mutate = ref.mutate;
              var loading = ref.loading;
return [_c('v-textarea',{attrs:{"no-resize":"","rows":4,"single-line":"","hint":"ENTER чтобы сохранить","label":"Название места работы","value":item.name,"loading":loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return mutate({
                variables: { id: item.id, name: $event.target.value },
              })}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-dialog',{attrs:{"value":_vm.removeId === item.id,"max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){_vm.removeId = item.id}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Удаление места работы")]),_c('v-card-text',[_vm._v("Это действие нельзя отменить")]),_c('v-card-actions',[_c('ApolloMutation',{attrs:{"mutation":_vm.deleteMutation,"variables":{ id: item.id }},on:{"done":function($event){_vm.removeId = '';
                        query.refetch();}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var mutate = ref.mutate;
                        var loading = ref.loading;
return [_c('v-btn',{attrs:{"text":"","color":"red","loading":loading},on:{"click":mutate}},[_vm._v(" удалить ")])]}}],null,true)}),_c('v-btn',{staticClass:"ml-2",attrs:{"text":""},on:{"click":function($event){_vm.removeId = ''}}},[_vm._v(" отменить ")])],1)],1)],1)]},proxy:true}],null,true)})]}}],null,true)})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }