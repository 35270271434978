







































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  AllDoctorWorkplaces,
  createDoctorWorkplace,
  deleteDoctorWorkplace,
  updateDoctorWorkplace,
} from "@/graphql/queries/DoctorsWorkplaces.graphql";
import PageCard from "@components/page/Card.vue";
import { hospitalNames, hospitalTypes } from "@/core/static/dict";
import { HospitalTypeEnum } from "@/generated/graphql";
import { Routes } from "@/types/core";
import { rules } from "@/plugins/vee-validate";
import { cyrillic_alpha_spaces, required } from "@/core/validation";

@Component({
  components: { PageCard },
})
export default class DoctorsWorkplaces extends Vue {
  protected valid = false;
  protected readonly rules = [cyrillic_alpha_spaces, required];
  protected readonly hospitalNames = hospitalNames;
  protected readonly hospitalTypes = hospitalTypes;

  protected readonly query = AllDoctorWorkplaces;
  protected readonly deleteMutation = deleteDoctorWorkplace;
  protected readonly createMutation = createDoctorWorkplace;
  protected readonly updatMutation = updateDoctorWorkplace;

  protected createWorkplaceName: string = "";
  protected createWorkplaceHospitalType = HospitalTypeEnum.Public;
  protected removeId: string = "";
  protected Routes = Routes;

  public created(): void {
    if (!this.VIEW_WORKPLACES) this.$router.push({ name: Routes.noRights });
  }
  get VIEW_WORKPLACES(): boolean {
    return this.$store.getters["session/isAllow"]("VIEW_WORKPLACES");
  }
}
