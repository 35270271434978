


















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PageCard from "@components/page/Card.vue";
import { Routes } from "@/types/core";
import { Scalars } from "@/generated/graphql";
import { cloneDeep } from "lodash";
import { DocumentNode } from "graphql";

type ValidationForm = Vue & {
  [key: string]: any;
  validate(): boolean;
};

export type Tab = {
  /**
   * Название таба
   */
  title: string;
  /**
   * Опинсание шапки для таблицы
   */
  headers: [{ text: string; value: string }, { text: string; value: string }];
  /**
   * Подпись поля создания элемента
   */
  labelCreateField: string;
  /**
   * Массив правил валидации полей редактирования и создания
   */
  rules: ((value: string) => true | string)[];
  /**
   * Название элемента для использования в окнах подтверждения
   */
  itemName: string;
  /**
   * Мутация на создание
   */
  createMutation: DocumentNode;
  /**
   * Мутация на обновление
   */
  updateMutation: DocumentNode;
  /**
   * Мутация на удаление
   */
  deleteMutation: DocumentNode;
  /**
   * Запрос на получение списка
   */
  query: DocumentNode;
  /**
   * ключ списка данных в запросе
   */
  fieldName: string;
  /**
   * Разрешение на создание
   */
  accessToCreate: boolean;

  /**
   * Разрешение на просмотр
   */
  accessToView: boolean;
};

@Component({
  components: { PageCard },
})
export default class GeneralEditorOfCatalog extends Vue {
  @Prop({ type: Array, required: true })
  readonly tabs!: Tab[];

  readonly Routes = Routes;

  queryData: Record<string, Array<any>> = {};

  newItem: Record<string, string> = {};

  removeId: string | null = null;

  tab = 0;

  loadingUpdate: string | null = null;

  createLoading = 0;

  deleteLoading = 0;

  error: any | null = null;

  // Важно! При добавлении новый REF вписать сюда
  $refs!: { [key: string]: ValidationForm; createForm: ValidationForm };

  get isLoading(): boolean {
    return this.$apollo.loading;
  }

  get accessToView(): boolean {
    return this.tabs[this.tab].accessToView;
  }

  get accessToCreate(): boolean {
    return this.tabs[this.tab].accessToCreate;
  }

  get query(): DocumentNode {
    return this.tabs[this.tab].query;
  }

  get deleteMutation(): DocumentNode {
    return this.tabs[this.tab].deleteMutation;
  }

  get createMutation(): DocumentNode {
    return this.tabs[this.tab].createMutation;
  }

  get tabLabels(): string[] {
    return this.tabs.map(({ title }) => title);
  }

  get labelCreateField(): string {
    return this.tabs[this.tab].labelCreateField;
  }

  get rules(): ((value: string) => true | string)[] {
    return this.tabs[this.tab].rules;
  }

  get headers(): { text: string; value: string }[] {
    return this.tabs[this.tab].headers;
  }

  get itemName(): string {
    return this.tabs[this.tab].itemName;
  }

  get fieldName(): string {
    return this.tabs[this.tab].fieldName;
  }

  get items(): Record<string, any>[] {
    return this.queryData[this.fieldName];
  }

  get editableField(): { text: string; value: string }[] {
    return this.headers.filter((head) => head.value !== "id");
  }

  @Watch("query", { immediate: true })
  handlerQuery(): void {
    this.$apollo.addSmartQuery(this.fieldName, {
      query: this.query,
      update: (data) => {
        this.queryData = { ...this.queryData, ...cloneDeep(data) };
      },
    });
  }

  // todo: нужно добавить вывод ошибок мутаций
  async mutationUpdate(
    item: Record<string, any> & { id: Scalars["ID"] }
  ): Promise<void> {
    try {
      this.error = null;
      this.loadingUpdate = item.id;

      if (!this.$refs[`forms-${item.id}`][0].validate()) {
        return;
      }

      await this.$apollo.mutate({
        mutation: this.tabs[this.tab].updateMutation,
        variables: { ...item },
        refetchQueries: [{ query: this.query }],
      });
    } catch (e) {
      this.error = e;
      console.error(e);
    } finally {
      this.loadingUpdate = null;
    }
  }

  async mutationCreate(): Promise<void> {
    if (!this.$refs.createForm.validate()) {
      return;
    }
    try {
      this.createLoading += 1;
      await this.$apollo.mutate({
        mutation: this.createMutation,
        variables: {
          ...this.newItem,
        },
        refetchQueries: [{ query: this.query }],
      });
      this.newItem = {};
      this.$refs.createForm?.reset();
    } catch (e) {
      this.error = e;
      console.error(e);
    } finally {
      this.createLoading -= 1;
    }
  }

  async mutationDelete(id: Scalars["ID"]): Promise<void> {
    try {
      this.deleteLoading += 1;
      await this.$apollo.mutate({
        mutation: this.deleteMutation,
        variables: {
          id,
        },
        refetchQueries: [{ query: this.query }],
      });
      this.removeId = null;
    } catch (e) {
      this.error = e;
      console.error(e);
    } finally {
      this.deleteLoading -= 1;
    }
  }

  created(): void {
    if (!this.accessToView) this.$router.push({ name: Routes.noRights });
  }
}
